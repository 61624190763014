body {
  background-color: #222;
  color: #eee;
}

.section-container {
  padding: 0 40px;
}

.main-content {
  padding: 20px 0;
}

.section-container.footer {
  color: #fff;
  padding-top: 0px;
  padding-bottom: 40px;
}

.footer-border {
  background-image: linear-gradient(45deg, rgb(0, 255, 153), rgb(0, 204, 255), rgb(153, 251, 255), rgb(255, 102, 102), rgb(255, 243, 92));
  height: 2px;
}

.footer-content {
  padding-top: 20px
}

.section-container.footer a { color: #fff; }