.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

@media (max-width: 500px) {
  .header-container { flex-direction: column; }
}

.logo {
  color: #fff;
  font-size: 32px;
  text-decoration: none;
  margin: 0;
}